body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type=file] {
  width: 100%;
}
.App {
  
}


.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.NotFound {
  padding-top: 100px;
}
.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.NewNote form textarea {
  height: 300px;
  font-size: 1.5rem;
}
.Notes form textarea {
  height: 300px;
  font-size: 1.5rem;
}

